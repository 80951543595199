<template>
  <div class="sidebar">
    <el-menu
      class="sidebar-el-menu"
      :default-active="onRoutes"
      :collapse="collapse"
      background-color="#324157"
      text-color="#bfcbd9"
      active-text-color="#20a0ff"
      unique-opened
      router
    >
      <template v-for="item in items">
        <template v-if="item.subs">
          <el-submenu :index="item.index" :key="item.index">
            <template #title>
              <i :class="item.icon"></i>
              <span>{{ item.title }}123</span>
            </template>
            <template v-for="subItem in item.subs">
              <el-submenu
                v-if="subItem.subs"
                :index="subItem.index"
                :key="subItem.index"
              >
                <template #title>{{ subItem.title }}</template>
                <el-menu-item
                  v-for="(threeItem, i) in subItem.subs"
                  :key="i"
                  :index="threeItem.index"
                  >{{ threeItem.title }}</el-menu-item
                >
              </el-submenu>
              <el-menu-item
                v-else
                :index="subItem.index"
                :key="subItem.index"
                >{{ subItem.title }}</el-menu-item
              >
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :key="item.index">
            <i :class="item.icon"></i>
            <template #title>{{ item.title }}</template>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
// import bus from "../common/bus";
export default {
  data () {
    return {
      items: [
        
      ],
      qunanxian1:[{
          icon: "el-icon-video-camera-solid",
          index: "live",
          title: "直播管理"
        },
       
        {
          icon: "el-icon-s-order",
          index: "registration",
          title: "报名表"
        },
        {
          icon: "el-icon-document",
          index: "homearticle",
          title: "文章管理"
        },
        {
          icon: "el-icon-user",
          index: "jurisdiction",
          title: "权限管理"
        },
        {
          icon: "el-icon-user",
          index: "liveding",
          title: "单页直播"
        },
        {
          icon: "el-icon-video-camera-solid",
          index: "yizhi",
          title: "医知医汇.net"
        },],
        qunanxian2:[{
          icon: "el-icon-video-camera-solid",
          index: "live",
          title: "直播管理"
        },
        {
          icon: "el-icon-s-order",
          index: "registration",
          title: "报名表"
        },],
        qunanxian3:[
        {
          icon: "el-icon-s-order",
          index: "registration",
          title: "报名表"
        },
        {
          icon: "el-icon-document",
          index: "homearticle",
          title: "文章管理"
        },
        ],
        qunanxian4:[
        {
          icon: "el-icon-s-order",
          index: "registration",
          title: "报名表"
        },
        {
          icon: "el-icon-document",
          index: "yizhi",
          title: "医知医汇.net"
        },
        ],
        qunanxian5:[
        {
          icon: "el-icon-video-camera-solid",
          index: "live",
          title: "直播管理"
        },
        ]
    };
  },
  computed: {
    onRoutes () {
      return this.$route.path.replace("/", "");
    },
    collapse () {
      return this.$store.state.collapse
    }
  },
  created(){
    const roles = localStorage.getItem('quanxian');
    console.log(roles)
    if(roles==1){
      this.items=this.qunanxian1
    }else if(roles==2){
      this.items=this.qunanxian2
    }else if(roles==3||roles==4){
      this.items=this.qunanxian3
    }else if(roles==5){
      this.items=this.qunanxian4
    }else if(roles > 819202100 && roles < 819999999){
      this.items=this.qunanxian5
    }
  }
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 250px;
}
.sidebar > ul {
  height: 100%;
}
</style>
