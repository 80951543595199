<template>
  <div class="header">
    <!-- 折叠按钮 -->
    <div class="collapse-btn" @click="collapseChage">
      <i v-if="!collapse" class="el-icon-s-fold"></i>
      <i v-else class="el-icon-s-unfold"></i>
    </div>
    <div class="logo">同享后台管理系统</div>
    <div class="header-right">
      <div class="header-user-con">
        <!-- 消息中心 -->
        <div class="btn-bell">
          <el-tooltip
            effect="dark"
            :content="message ? `有${message}条未读消息` : `消息中心`"
            placement="bottom"
          >
            <router-link to="/tabs">
              <i class="el-icon-bell"></i>
            </router-link>
          </el-tooltip>
          <span class="btn-bell-badge" v-if="message"></span>
        </div>
        <!-- 用户头像 -->
        <div class="user-avator">
          <img
            src="https://tse1-mm.cn.bing.net/th/id/R-C.ca36f10250834ca21d95afb1a8140425?rik=Y2rd4ui%2bwWygwA&riu=http%3a%2f%2fimg.crcz.com%2fallimg%2f201912%2f22%2f1576983236584715.jpg&ehk=LRzO79ZQtITBesvS085KqnL7lsEO9xMzvMajtwuDEGg%3d&risl=&pid=ImgRaw&r=0&sres=1&sresct=1"
          />
        </div>
        <!-- 用户名下拉菜单 -->
        <el-dropdown class="user-name" trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            {{ username }}
            <i class="el-icon-caret-bottom"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <!-- <a
                href="https://github.com/lin-xin/vue-manage-system"
                target="_blank"
              >
                <el-dropdown-item>项目仓库</el-dropdown-item>
              </a> -->
              <el-dropdown-item command="loginout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import qs from 'qs'
import { getusersbypage } from '../api/index'
export default {
  data () {
    return {
      fullscreen: false,
      name: "linxin",
      message: 0,
      tableData:[],
      username:''
    };
  },
  computed: {
   
    collapse () {
      return this.$store.state.collapse;
    }
  },
  methods: {
    // 用户名下拉菜单选择事件
    handleCommand (command) {
      if (command == "loginout") {
        localStorage.removeItem("token");
        this.$router.push("/login");
      }
    },
    // 侧边栏折叠
    collapseChage () {
      this.$store.commit("hadndleCollapse", !this.collapse);
    },
    // 更新用户名字
    usernamelist(){
      let app = qs.stringify({
        numperpage: 20,//默认20
        pagenumber: 0,
      })
      getusersbypage(app).then(res => {
        // console.log(res)
        const userid = localStorage.getItem('userid');
        // this.tableData = res.userrecords
        for(var i=0;i<res.userrecords.length;i++){
          if(res.userrecords[i].userid==userid){
            this.username=res.userrecords[i].username
          }
        }

      })
    }
  },
  mounted () {
    this.usernamelist()
    if (document.body.clientWidth < 1500) {
      this.collapseChage();
    }
  }
};
</script>
<style scoped>
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  font-size: 22px;
  color: #fff;
}
.collapse-btn {
  float: left;
  padding: 0 21px;
  cursor: pointer;
  line-height: 70px;
}
.header .logo {
  float: left;
  width: 250px;
  line-height: 70px;
}
.header-right {
  float: right;
  padding-right: 50px;
}
.header-user-con {
  display: flex;
  height: 70px;
  align-items: center;
}
.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 24px;
}
.btn-bell,
.btn-fullscreen {
  position: relative;

  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}
.btn-bell-badge {
  position: absolute;
  right: 0;
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #fff;
}
.btn-bell .el-icon-bell {
  color: #fff;
}
.user-name {
  margin-left: 10px;
}
.user-avator {
  margin: 0 20px;
}
.user-avator img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.el-dropdown-link {
  color: #fff;
  cursor: pointer;
}
.el-dropdown-menu__item {
  text-align: center;
}
</style>
