import request from '../utils/request';
// import qs from "qs"
let text4 = `tx4sdev.tongxianglive.cn`
// let tx4sqn = `/tx4qecdn.tongxianglive.cn`
export const fetchData = query => {
  return request({
    url: './table.json',
    method: 'get',
    params: query
  });
};
export const longin = query => {
  return request({
    url: `https://${text4}/tx4s/tx4sweblogin`,
    method: 'post',
    data: query
  });
};

// 测试接口
export const todoceshi = (query) => {
  return request({
    url: `https://${text4}/tx4s/hello`,
    method: 'post',
    data: query
  });
};
// 页数
export const change = (query) => {
  return request({
    url: `https://${text4}/tx4s/geteventsbypage`,
    method: 'post',
    data: query
  });
};
// 更改
export const edmit = (query) => {
  return request({
    url: `https://${text4}/tx4s/updateevent`,
    method: 'post',
    data: query
  });
};
// 菜单
export const menu = (query) => {
  return request({
    url: `https://${text4}/tx4s/updateeventtab`,
    method: 'post',
    data: query
  });
};
// 菜单
export const viewmenu = (query) => {
  return request({
    url: `https://${text4}/tx4s/readeventtab`,
    method: 'post',
    data: query
  });
};
// 报名表显示名字
export const baoming = (query) => {
  return request({
    url: `https://${text4}/tx4s/getbaomingbiaolist`,
    method: 'post',
    data: query
  });
};
// 报名表显示内容
export const selerbaoming = (query) => {
  return request({
    url: `https://${text4}/tx4s/getbaomingbiaodetail`,
    method: 'post',
    data: query
  });
};
// 报名表编辑提交
export const baomingelete = (query) => {
  return request({
    url: `https://${text4}/tx4s/savebaomingbiaodetail`,
    method: 'post',
    data: query
  });
};
// 报名表编辑删除
export const deletebaoming = (query) => {
  return request({
    url: `https://${text4}/tx4s/deletebaomingbiao`,
    method: 'post',
    data: query
  });
};
// 报名表绑定直播
export const baominglive = (query) => {
  return request({
    url: `https://${text4}/tx4s/attachbaomingbiao`,
    method: 'post',
    data: query
  });
};
// 取消报名表绑定直播
export const detachbaomingbiao = (query) => {
  return request({
    url: `https://${text4}/tx4s/detachbaomingbiao`,
    method: 'post',
    data: query
  });
};
// 报名表详情
export const baomingbiaoreport = (query) => {
  return request({
    url: `https://${text4}/tx4s/baomingbiaoreport`,
    method: 'post',
    data: query
  });
};
// 把名表下载取回文件流
export const baomingbiaoreportexcel = (baoid, id, query) => {
  return request({
    url: `https://tx4sdev.tongxianglive.cn/tx4s/getbaomingbiaoreportexcel/baomingbiaoid/${baoid}/video_id/${id}`,
    method: 'get',
    data: query
  });
};
// 直播留言
export const getcommentsbypage = (query) => {
  return request({
    url: `https://${text4}/tx4s/getcommentsbypage`,
    method: 'post',
    data: query
  });
};
// 直播评论允许
export const approvecommenttest = (query) => {
  return request({
    url: `https://${text4}/tx4s/approvecomment`,
    method: 'post',
    data: query
  });
};
// 直播评论拒绝
export const rejectcommenttest = (query) => {
  return request({
    url: `https://${text4}/tx4s/rejectcomment`,
    method: 'post',
    data: query
  });
};
// 直播评论是否审核
export const setcommentautoapprove = (query) => {
  return request({
    url: `https://${text4}/tx4s/setcommentautoapprove`,
    method: 'post',
    data: query
  });
};
// websocket接受
export const setserverwsid = (query) => {
  return request({
    url: `https://tx4qecdn.tongxianglive.cn/tx3/public/index.php/index/Txws/setserverwsid`,
    method: 'post',
    data: query
  });
};
// 直播每分钟的参数
export const getonlinedatabyminute = (query) => {
  return request({
    url: `https://${text4}/tx4s/getonlinedatabyminute`,
    method: 'post',
    data: query
  });
};
// 直播
export const getreportsummary = (query) => {
  return request({
    url: `https://${text4}/tx4s/getreportsummary`,
    method: 'post',
    data: query
  });
};
// 人员明细
export const getuserwatchreport = (query) => {
  return request({
    url: `https://${text4}/tx4s/getuserwatchreport`,
    method: 'post',
    data: query
  });
};
// 回放视频
export const getlatestvodm3u8 = (query) => {
  return request({
    url: `https://${text4}/tx4s/getlatestvodm3u8`,
    method: 'post',
    data: query
  });
};
// 回放视频预览
export const updatevodm3u8 = (query) => {
  return request({
    url: `https://${text4}/tx4s/previewvodm3u8`,
    method: 'post',
    data: query
  });
};
// 回放视频选中添加部分操作
export const keepselectedvodm3u8 = (query) => {
  return request({
    url: `https://${text4}/tx4s/keepselectedvodm3u8`,
    method: 'post',
    data: query
  });
};
// 回放视频选中删除部分操作
export const removeselectedvodm3u8 = (query) => {
  return request({
    url: `https://${text4}/tx4s/removeselectedvodm3u8`,
    method: 'post',
    data: query
  });
};
// 回放视频清除操作
export const cleartvodm3u8 = (query) => {
  return request({
    url: `https://${text4}/tx4s/cleartvodm3u8`,
    method: 'post',
    data: query
  });
};
// 使用网络视频
export const save3dvodm3u8 = (query) => {
  return request({
    url: `https://${text4}/tx4s/save3dvodm3u8`,
    method: 'post',
    data: query
  });
};
// 暂不回放
export const stopvod = (query) => {
  return request({
    url: `https://${text4}/tx4s/stopvod`,
    method: 'post',
    data: query
  });
};
// 开始回放
export const startvod = (query) => {
  return request({
    url: `https://${text4}/tx4s/startvod`,
    method: 'post',
    data: query
  });
};
// 设置提交微赞
export const setmicrosite = (query) => {
  return request({
    url: `https://${text4}/tx4s/setmicrosite`,
    method: 'post',
    data: query
  });
};
// 设置提交微赞
export const getmicrosite = (query) => {
  return request({
    url: `https://${text4}/tx4s/getmicrosite`,
    method: 'post',
    data: query
  });
};
// 复制报名表
export const copybaomingbiao = (query) => {
  return request({
    url: `https://${text4}/tx4s/copybaomingbiao`,
    method: 'post',
    data: query
  });
};
// 公众号
export const gettokenbytxliveid = (query) => {
  return request({
    url: `https://${text4}/tx4s/gettokenbytxliveid`,
    method: 'post',
    data: query
  });
};
// 公众号
export const settokenbytxliveid = (query) => {
  return request({
    url: `https://${text4}/tx4s/settokenbytxliveid`,
    method: 'post',
    data: query
  });
};
//文章列表
export const getyzmessagesbypage = (query) => {
  return request({
    url: `https://${text4}/tx4s/getyzarticlesbypage`,
    method: 'post',
    data: query
  });
};
//文章具体内容
export const getyzarticle = (query) => {
  return request({
    url: `https://${text4}/tx4s/getyzarticle`,
    method: 'post',
    data: query
  });
};
//文章tijiao
export const updateyzarticle = (query) => {
  return request({
    url: `https://${text4}/tx4s/updateyzarticle`,
    method: 'post',
    data: query
  });
};
//文章显示与不显示
export const aritcletoggledisplay = (query) => {
  return request({
    url: `https://${text4}/tx4s/aritcletoggledisplay`,
    method: 'post',
    data: query
  });
};
//提交动画
export const wscontrolcommand = (query) => {
  return request({
    url: `https://${text4}/tx4s/wscontrolcommand`,
    method: 'post',
    data: query
  });
};
//取回全局广告
export const getglobalad = (query) => {
  return request({
    url: `https://${text4}/tx4s/getglobalad`,
    method: 'post',
    data: query
  });
};
// 发送保存全局广告
export const updateglobalad = (query) => {
  return request({
    url: `https://${text4}/tx4s/updateglobalad`,
    method: 'post',
    data: query
  });
};
// 权限管理获取用户
export const getusersbypage = (query) => {
  return request({
    url: `https://${text4}/tx4s/getusersbypage`,
    method: 'post',
    data: query
  });
};
// 权限管理编辑添加
export const updateuser = (query) => {
  return request({
    url: `https://${text4}/tx4s/updateuser`,
    method: 'post',
    data: query
  });
};
// 发布发送
export const publish = (query) => {
  return request({
    url: `https://${text4}/tx4s/publish`,
    method: 'post',
    data: query
  });
};
// 发布是否成功
export const getpublishstatus = (query) => {
  return request({
    url: `https://${text4}/tx4s/getpublishstatus`,
    method: 'post',
    data: query
  });
};
// 图文一获取
export const gettuwen = (query) => {
  return request({
    url: `https://${text4}/tx4s/gettuwen`,
    method: 'post',
    data: query
  });
};
// 图文一保存
export const savetuwen = (query) => {
  return request({
    url: `https://${text4}/tx4s/savetuwen`,
    method: 'post',
    data: query
  });
};
// 保存直播广告
export const savetuwenlink = (query) => {
  return request({
    url: `https://${text4}/tx4s/savetuwenlink`,
    method: 'post',
    data: query
  });
};
// 查看直播广告
export const gettuwenlink = (query) => {
  return request({
    url: `https://${text4}/tx4s/gettuwenlink`,
    method: 'post',
    data: query
  });
};
// 获取全部通知
export const getdefaultnotice = (query) => {
  return request({
    url: `https://${text4}/tx4s/getdefaultnotice`,
    method: 'post',
    data: query
  });
};
// 发送列表通知
export const savenotice = (query) => {
  return request({
    url: `https://${text4}/tx4s/savenotice`,
    method: 'post',
    data: query
  });
};
//加载列表通知
export const getnotice = (query) => {
  return request({
    url: `https://${text4}/tx4s/getnotice`,
    method: 'post',
    data: query
  });
};
// 使用列表通知
export const setnotice = (query) => {
  return request({
    url: `https://${text4}/tx4s/setnotice`,
    method: 'post',
    data: query
  });
};
// 左侧列表通知
export const saveandsetnotice = (query) => {
  return request({
    url: `https://${text4}/tx4s/saveandsetnotice`,
    method: 'post',
    data: query
  });
};
// 列表通知开启
export const enablenotice = (query) => {
  return request({
    url: `https://${text4}/tx4s/enablenotice`,
    method: 'post',
    data: query
  });
};
// 列表通知关闭
export const disablenotice = (query) => {
  return request({
    url: `https://${text4}/tx4s/disablenotice`,
    method: 'post',
    data: query
  });
};
// 查看是否在开启通知
export const getnoticestatus = (query) => {
  return request({
    url: `https://${text4}/tx4s/getnoticestatus`,
    method: 'post',
    data: query
  });
};
// 预热状态
export const pushqlive = (query) => {
  return request({
    url: `https://${text4}/tx4s/pushqlive`,
    method: 'post',
    data: query
  });
};
// 查看预热状态
export const checkpushing = (query) => {
  return request({
    url: `https://${text4}/tx4s/checkpushing`,
    method: 'post',
    data: query
  });
};
// 调用洁面
export const getremotecommand = (query) => {
  return request({
    url: `https://${text4}/tx4s/getremotecommand`,
    method: 'post',
    data: query
  });
};
// 调用axaios
export const remotecommand = (query) => {
  return request({
    url: `https://${text4}/tx4s/remotecommand`,
    method: 'post',
    data: query
  });
};
// 调用axaios
export const mpqrcode = (query) => {
  return request({
    url: `https://tx4qecdn.tongxianglive.cn/tx3/public/index.php/index/Txsa/mpqrcode`,
    method: 'post',
    data: query
  });
};
// 查看直播广告
export const publishcongjstest = (query) => {

  return request({
    url: `https://tx4sdev.tongxianglive.cn/tx4s/publishcongjstest/txliveid/${query}`,
    method: 'get',
    data: query
  });
};


// 结束
export const start = (id, query) => {
  return request({
    url: `https://tx4qecdn.tongxianglive.cn/tx3/public/index.php/index/Txws/stoplive/id/${id}`,
    method: 'get',
    params: query
  });
};
// 开始
export const stop = (id, query) => {
  return request({
    url: `https://tx4qecdn.tongxianglive.cn/tx3/public/index.php/index/Txws/startlive/id/${id}`,
    method: 'get',
    params: query
  });
};
// 提交精选
export const setjingxuan = (query) => {
  return request({
    url: `https://${text4}/tx4s/setjingxuan`,
    method: 'post',
    data: query
  });
};
// 获取精选内容
export const getjingxuan = (query) => {
  return request({
    url: `https://${text4}/tx4s/getjingxuan`,
    method: 'post',
    data: query
  });
};
// 获取轮播图
export const getlunbotu = (query) => {
  return request({
    url: `https://${text4}/tx4s/getlunbotu`,
    method: 'post',
    data: query
  });
};
// 提交轮播图
export const setlunbotu = (query) => {
  return request({
    url: `https://${text4}/tx4s/setlunbotu`,
    method: 'post',
    data: query
  });
};
// export const zhibo = query => {
//   return request({
//       url: 'httpss://www.fastmock.site/mock/c198cda921f6c3c5111bcde6092e883c/api/api/zhibo',
//       method: 'get',
//       params: query
//   });
// };