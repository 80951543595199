<template>
  <div class="about">
    <v-header />
    <v-sidebar />
    <div class="content-box" :class="{ 'content-collapse': collapse }">
      <!-- <v-tags></v-tags> -->
      <div class="content">
        <router-view v-slot="{ Component }">
          <transition name="move" mode="out-in">
            <keep-alive :include="tagsList">
              <component :is="Component" />
            </keep-alive>
          </transition>
        </router-view>
        <!-- <el-backtop target=".content"></el-backtop> -->
      </div>
    </div>
  </div>
</template>
<script>
import vHeader from "../components/Header";
import vSidebar from "../components/Sidebar";
import vTags from "../components/Tags.vue";
export default {
  components: {
    vHeader,
    vSidebar,
    vTags
  },
  computed: {
    tagsList () {
      return this.$store.state.tagsList.map(item => item.name);
    },
    collapse () {
      return this.$store.state.collapse;
    }
  },
  created(){
    let aDatas = new Date();
    let dateValues = aDatas.getFullYear() + "-" + (aDatas.getMonth() + 1) + "-" + aDatas.getDate();
    console.log(dateValues)
    const timedata = localStorage.getItem('timedata');
    console.log(timedata)
    if(timedata==null){
      localStorage.removeItem("token");
      localStorage.removeItem("userid");
      localStorage.removeItem("quanxian");
      this.$router.push("/login");
    }else if(timedata!=null){
      if(timedata>dateValues){
        console.log('正常登录')
      }else if(dateValues>timedata){
        localStorage.removeItem("token");
        localStorage.removeItem("userid");
        localStorage.removeItem("quanxian");
        localStorage.removeItem("timedata");
        this.$router.push("/login");
      }
    }
  }
};
</script>
