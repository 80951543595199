import axios from 'axios';
// import axios from 'axios';
// Vue.prototype.$axios = axios
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'

const service = axios.create({
  // process.env.NODE_ENV === 'development' 来判断是否开发环境
  // easy-mock服务挂了，暂时不使用了
  // baseURL: 'https://www.easy-mock.com/mock/592501a391470c0ac1fab128',
  timeout: 100000
});

service.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    console.log(error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 201) {
      //  console.log(response)
      return response.data;
    } else {
      console.log(123)
      Promise.reject();

    }
  },
  error => {
    console.log(error);
    return Promise.reject(error);
  }
);

export default service;