import {
  createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import './assets/css/icon.css'

import VideoPlayer from 'vue-video-player/src'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import hls from 'videojs-contrib-hls'
// Vue.use()


import {
  DropdownMenu,
  DropdownItem,
  RadioGroup,
  Radio,
  Swipe,
  SwipeItem,
} from 'vant';
const app = createApp(App)
installElementPlus(app)

app
  .use(store)
  .use(router)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(RadioGroup)
  .use(Radio)
  .use(Swipe)
  .use(SwipeItem)
  .use(VideoPlayer)
  .use(hls)
  .mount('#app')
