import {
  createRouter,
  createWebHistory
} from "vue-router";
import Home from "../views/Home.vue";


var routes = [{
    path: '/',
    redirect: '/homearticle'
  }, {
    path: "/",
    name: "Home",
    component: Home,
    children: [

      {
        path: "/live",
        name: "live",
        meta: {
          title: '直播相关',
          live: true
        },
        component: () => import(
          /* webpackChunkName: "dashboard" */
          "../views/live/live.vue")
      },
      {
        path: "/yizhi",
        name: "yizhi",
        meta: {
          title: '医知医汇.net',
          yizhi: true
        },
        component: () => import(
          /* webpackChunkName: "dashboard" */
          "../views/yizhi.net/home")
      },
      {
        path: "/announ",
        name: "announ",
        meta: {
          title: '通知'
        },
        component: () => import(
          /* webpackChunkName: "dashboard" */
          "../views/live/announ/index.vue")
      },
      {
        path: "/jurisdiction",
        name: "jurisdiction",
        meta: {
          title: '直播相关',
          jurisdiction: true
        },
        component: () => import(
          /* webpackChunkName: "dashboard" */
          "../views/jurisdiction/home/index.vue")
      },
      {
        path: "/MicroStation",
        name: "MicroStation",
        meta: {
          title: '微站',
          MicroStation: true
        },
        component: () => import(
          /* webpackChunkName: "dashboard" */
          "../views/MicroStation/index.vue")
      },
      {
        path: "/quil",
        name: "quil",
        meta: {
          title: '富文本'
        },
        component: () => import(
          /* webpackChunkName: "dashboard" */
          "../views/quil/quil.vue")
      },
      {
        path: "/registration",
        name: "registration",
        meta: {
          title: '报名表'
        },
        component: () => import(
          /* webpackChunkName: "tabs" */
          "../views/registration/index.vue")
      },
      {
        path: "/leaving",
        name: "leaving",
        meta: {
          title: '留言'
        },
        component: () => import(
          /* webpackChunkName: "i18n" */
          "../views/leaving/index.vue")
      },
      {
        path: "/announcementlive",
        name: "announcementlive",
        meta: {
          title: '通知'
        },
        component: () => import(
          /* webpackChunkName: "i18n" */
          "../views/announcementLIVE/index.vue")
      },
      {
        path: "/echarts",
        name: "echarts",
        meta: {
          title: '数据分析'
        },
        component: () => import(
          /* webpackChunkName: "i18n" */
          "../views/echarts/index.vue")
      },
      {
        path: "/audience",
        name: "audience",
        meta: {
          title: '观众明细'
        },
        component: () => import(
          /* webpackChunkName: "i18n" */
          "../views/audience/index.vue")
      },
      {
        path: "/control",
        name: "control",
        meta: {
          title: '远程控制'
        },
        component: () => import(
          /* webpackChunkName: "i18n" */
          "../views/live/control/index.vue")
      },
      {
        path: "/videolive",
        name: "videolive",
        meta: {
          title: '回放编辑'
        },
        component: () => import(
          /* webpackChunkName: "i18n" */
          "../views/videolive/index.vue")
      },
      {
        path: "/official",
        name: "official",
        meta: {
          title: '公众号'
        },
        component: () => import(
          /* webpackChunkName: "i18n" */
          "../views/official/index.vue")
      },
      {
        path: "/dashboard",
        name: "dashboard",
        meta: {
          title: '系统首页'
        },
        component: () => import(
          /* webpackChunkName: "dashboard" */
          "../views/Dashboard.vue")
      },
      {
        path: "/editing",
        name: "editing",
        meta: {
          title: '更改频道'
        },
        component: () => import(
          /* webpackChunkName: "dashboard" */
          "../views/channel/Editing.vue")
      }, {
        path: "/table",
        name: "basetable",
        meta: {
          title: '表格'
        },
        component: () => import(
          /* webpackChunkName: "table" */
          "../views/BaseTable.vue")
      }, {
        path: "/charts",
        name: "basecharts",
        meta: {
          title: '图表'
        },
        component: () => import(
          /* webpackChunkName: "charts" */
          "../views/BaseCharts.vue")
      }, {
        path: "/form",
        name: "baseform",
        meta: {
          title: '表单'
        },
        component: () => import(
          /* webpackChunkName: "form" */
          "../views/BaseForm.vue")
      }, {
        path: "/tabs",
        name: "tabs",
        meta: {
          title: 'tab标签'
        },
        component: () => import(
          /* webpackChunkName: "tabs" */
          "../views/Tabs.vue")
      },
      {
        path: "/list",
        name: "list",
        meta: {
          title: 'list'
        },
        component: () => import(
          /* webpackChunkName: "tabs" */
          "../views/channel/List.vue")
      },
      {
        path: "/permission",
        name: "permission",
        meta: {
          title: '权限管理',
          // permission: true
        },
        component: () => import(
          /* webpackChunkName: "permission" */
          "../views/Permission.vue")
      }, {
        path: "/i18n",
        name: "i18n",
        meta: {
          title: '国际化语言'
        },
        component: () => import(
          /* webpackChunkName: "i18n" */
          "../views/I18n.vue")
      }, {
        path: "/upload",
        name: "upload",
        meta: {
          title: '上传插件'
        },
        component: () => import(
          /* webpackChunkName: "upload" */
          "../views/Upload.vue")
      }, {
        path: "/icon",
        name: "icon",
        meta: {
          title: '自定义图标'
        },
        component: () => import(
          /* webpackChunkName: "icon" */
          "../views/Icon.vue")
      },
      {
        path: "/creat",
        name: "Creat",
        meta: {
          title: '创建直播'
        },
        component: () => import(
          /* webpackChunkName: "login" */
          "../views/Creat.vue")
      },
      {
        path: "/channel",
        name: "channel",
        meta: {
          title: '创建直播'
        },
        component: () => import(
          /* webpackChunkName: "login" */
          "../views/channel.vue")
      },
      {
        path: "/homearticle",
        name: "homearticle",
        meta: {
          title: '文章列表',
          homearticle: true
        },
        component: () => import(
          /* webpackChunkName: "login" */
          "../views/articleImage/home/index.vue")
      },

      {
        path: "/advertisement",
        name: "advertisement",
        meta: {
          title: '文章列表'
        },
        component: () => import(
          /* webpackChunkName: "login" */
          "../views/articleImage/advertisement/index.vue")
      },
      {
        path: "/edmit",
        name: "edmit",
        meta: {
          title: '文章列表',
          edmit: true,
        },
        component: () => import(
          /* webpackChunkName: "login" */
          "../views/articleImage/edmit/index.vue")
      },
      {
        path: "/management",
        name: "Management",
        meta: {
          title: '直播更改'
        },
        children: [{
            path: "/information",
            name: "information",

            component: () => import(
              /* webpackChunkName: "login" */
              "../views/information.vue")
          },
          {
            path: "/broadcasting",
            name: "broadcasting",
            meta: {
              title: '开播设置'
            },
            component: () => import(
              /* webpackChunkName: "login" */
              "../views/broadcasting.vue")
          },
          {
            path: "/advanced",
            name: "Advanced",
            meta: {
              title: '直播参数'
            },
            component: () => import(
              /* webpackChunkName: "login" */
              "../views/Advanced.vue")
          },

          // {
          //   path: "/edmit",
          //   name: "edmit",
          //   meta: {
          //     title: '文章编辑'
          //   },
          //   component: () => import(
          //     /* webpackChunkName: "login" */
          //     "../views/articleImage/edmit/index.vue")
          // }
        ],
        redirect: "/information",
        component: () => import(
          /* webpackChunkName: "login" */
          "../views/Management.vue")
      },
      {
        path: "/liveding",
        name: "liveding",
        meta: {
          title: '添加'
        },
        component: () => import(
          /* webpackChunkName: "login" */
          "../views/liveding/home")
      },
    ]
  }, {
    path: "/login",
    name: "Login",
    meta: {
      title: '登录'
    },

    component: () => import(
      /* webpackChunkName: "login" */
      "../views/Login.vue")
  },
  {
    path: "/edit",
    name: "edit",
    meta: {
      title: '编辑'
    },
    component: () => import(
      /* webpackChunkName: "login" */
      "../views/live/edit.vue")
  },

  {
    path: "/addlive",
    name: "addlive",
    meta: {
      title: '添加'
    },
    component: () => import(
      /* webpackChunkName: "login" */
      "../views/live/addlive.vue")
  },
  {
    path: "/selected",
    name: "selected",
    meta: {
      title: '精选'
    },
    component: () => import(
      /* webpackChunkName: "login" */
      "../views/yizhi.net/components/selected")
  },
  {
    path: "/Carousel",
    name: "Carousel",
    meta: {
      title: '精选'
    },
    component: () => import(
      /* webpackChunkName: "login" */
      "../views/yizhi.net/components/lunbolist")
  },
  // // 广告
  // {
  //   path: "/advertisement",
  //   name: "advertisement",
  //   meta: {
  //     title: '添加'
  //   },
  //   component: () => import(
  //     /* webpackChunkName: "login" */
  //     "../views/live/advertisement/index.vue")
  // },
  {
    path: "/image",
    name: "image",
    meta: {
      title: '添加'
    },
    component: () => import(
      /* webpackChunkName: "login" */
      "../views/live/imagetext/index.vue")
  },
  

];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | vue-manage-system`;
  const role = localStorage.getItem('token');
  const roles = localStorage.getItem('quanxian');
  // if (roles === '1') {
  //  
  console.log(routes)
  if (!role && to.path !== '/login') {
    next('/login');
  } else if (to.meta.live || to.meta.MicroStation) {
    // 如果是管理员权限则可进入
    roles === '2' || roles === '1' || roles > 819202100 && roles < 819999999 ?
      next() :
      next('/permission');
  } else if (to.meta.homearticle ) {
    // 如果是管理员权限则可进入
    roles === '1' || roles === '3' || roles === '4' ?
      next() :
      next('/permission');
  }else if ( to.meta.edmit) {
    // 如果是管理员权限则可进入
    roles === '1' || roles === '3' || roles === '4'|| roles === '5' ?
      next() :
      next('/permission');
  }  else if (to.meta.jurisdiction) {
    // 如果是管理员权限则可进入
    roles === '1' ?
      next() :
      next('/permission');
  }else if (to.meta.yizhi) {
    // 如果是管理员权限则可进入
    roles === '1' || roles === '5'?
      next() :
      next('/permission');
  } else {
    next();
  }
});

export default router;